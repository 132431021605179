import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';

export interface IEmailWorkflow extends IBaseEntity {
    id: number;
    entityId: number;
    type: any;
    subject: any;
    body: any;
    context: any;
    workflowConditions: any;
    notificationType: any;
    workflowUsers: any;
    wdaysOfWeek: any;
    mweekOfMonth: any;
    mmonthsOfYear: any;
    startTime: any;
    endTime: any;
    runInterval: any;
    scheduledAt: any;
    preBody: any;
    postBody: any;
    activated: any;
    emails: any;
    sendToCustomer: any;
    useLastUpdate: any;
    useCreatedOn: any;
    generatePdf: any;
    generateCsv: any;
    smsNumbers: any;
    whatsappNumbers: any;
    sendToSupplier: any;
    useEvent: any;
}

export class EmailWorkflow extends BaseEntity implements IEmailWorkflow {
    id: number;
    body: any;
    context: any;
    entityId: number;
    subject: any;
    type: any;
    workflowConditions: any;
    workflowUsers: any;
    notificationType: any;
    wdaysOfWeek: any;
    mweekOfMonth: any;
    mmonthsOfYear: any;
    startTime: any;
    endTime: any;
    runInterval: any;
    scheduledAt: any;
    preBody: any;
    postBody: any;
    activated: any;
    emails: any;
    sendToCustomer: any;
    useLastUpdate: any;
    useCreatedOn: any;
    generatePdf: any;
    generateCsv: any;
    smsNumbers: any;
    whatsappNumbers: any;
    sendToSupplier: any;
    useEvent: any;

    constructor(emailWorkflow?) {
        super();
        if (emailWorkflow) {
            this.id = emailWorkflow.id;
            this.body = emailWorkflow.body;
            this.context = emailWorkflow.context;
            this.entityId = emailWorkflow.entityId;
            this.subject = emailWorkflow.subject;
            this.type = emailWorkflow.type;
            this.workflowUsers = emailWorkflow.workflowUsers;
            this.workflowConditions = emailWorkflow.workflowConditions;
            this.wdaysOfWeek = emailWorkflow.wdaysOfWeek;
            this.mweekOfMonth = emailWorkflow.mweekOfMonth;
            this.mmonthsOfYear = emailWorkflow.mmonthsOfYear;
            this.startTime = emailWorkflow.startTime;
            this.endTime = emailWorkflow.endTime;
            this.runInterval = emailWorkflow.runInterval;
            this.scheduledAt = emailWorkflow.scheduledAt;
            this.preBody = emailWorkflow.preBody;
            this.postBody = emailWorkflow.postBody;
            this.activated = emailWorkflow.activated;
            this.emails = emailWorkflow.emails;
            this.sendToCustomer = emailWorkflow.sendToCustomer;
            this.useLastUpdate = emailWorkflow.useLastUpdate;
            this.useCreatedOn = emailWorkflow.useCreatedOn;
            this.generatePdf = emailWorkflow.generatePdf;
            this.generateCsv = emailWorkflow.generateCsv;
            this.notificationType = emailWorkflow.notificationType;
            this.smsNumbers = emailWorkflow.smsNumbers;
            this.whatsappNumbers = emailWorkflow.whatsappNumbers;
            this.sendToSupplier = emailWorkflow.sendToSupplier;
            this.useEvent = emailWorkflow.useEvent;
        }
    }

    clone(): EmailWorkflow {
        return new EmailWorkflow(this);
    }
}
